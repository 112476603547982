<template>
  <div>
    <div v-if="configurationFile">
      <v-row>
        <v-col cols="12" sm="auto">
          <h1 class="text-left">{{ configurationFile.filename }}</h1>
          <h2 class="text-left">{{ configurationFile.order }}</h2>
        </v-col>
        <v-col cols="12" sm="auto">
          <v-btn :block="$vuetify.breakpoint.mobile" depressed class="align-self-center mt-2 ml-md-4 mb-3 mb-md-0" @click="editConfigurationFile">
            <v-icon left>mdi-pencil-outline</v-icon>
            Edit
          </v-btn>
          <v-btn
            :block="$vuetify.breakpoint.mobile"
            color="error"
            outlined
            depressed
            class="align-self-center mt-2 ml-md-4 mb-3 mb-md-0"
            @click="deleteConfigurationFile"
          >
            <v-icon left>mdi-delete</v-icon>
            Delete
          </v-btn>
          <v-btn class="align-self-center mt-2 ml-md-4 mb-3 mb-md-0" :loading="downloading" depressed @click="download">
            <v-icon left>mdi-script-text-outline</v-icon>
            Download
          </v-btn>
        </v-col>
      </v-row>

      <v-simple-table dense class="my-4">
        <tbody>
          <tr>
            <td class="font-weight-bold">Id:</td>
            <td>{{ configurationFile.id }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Version:</td>
            <td>{{ configurationFile.version }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">File type:</td>
            <td>{{ configurationFile.fileType }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Path:</td>
            <td>{{ configurationFile.path }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Size:</td>
            <td>{{ configurationFile.fileSize }} bytes</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Description:</td>
            <td>{{ configurationFile.description }}</td>
          </tr>
          <tr>
            <td class="font-weight-bold">Enabled:</td>
            <td>
              <readonly-checkbox :value="!configurationFile.disabled" />
            </td>
          </tr>
          <tr>
            <td class="font-weight-bold">Last updated:</td>
            <td><last-updated-field :item="configurationFile" /></td>
          </tr>
        </tbody>
      </v-simple-table>

      <div class="mt-8">
        <h3>Configurations using this file</h3>
        <v-data-table
          :headers="[
            { text: 'Name', value: 'configurationName' },
            { text: 'Enabled', value: 'disabled' },
          ]"
          :items="configurationFile.configurations"
          v-bind="$util.dataTableAttributes()"
        >
          <template #[`item.configurationName`]="{ item }">
            <router-link :to="{ name: 'ConfigurationDetails', params: { Id: item.id } }">{{ item.configurationName }}</router-link>
          </template>
          <template #[`item.disabled`]="{ item }">
            <readonly-checkbox :value="!item.disabled" />
          </template>
        </v-data-table>
      </div>
    </div>
    <create-edit-configuration-file ref="CreateEditConfigurationFileRef" @configurationfile-updated="bubbleEmit" />
  </div>
</template>

<script>
import apiService from '@/services/apiService';
import CreateEditConfigurationFile from '../Dialogs/CreateEditConfigurationFile.vue';
import ReadonlyCheckbox from '@/components/ReadonlyCheckbox.vue';

export default {
  components: { CreateEditConfigurationFile, ReadonlyCheckbox },
  name: 'configurationFileDetails',
  data: () => ({
    downloading: false,
    loading: true,
    configurationFile: {},
  }),
  methods: {
    async getConfigurationFile() {
      this.contentVisible = false;
      this.loading = true;
      this.configurationFile = await apiService.getConfigurationFileById(this.$route.params.Id);
      this.loading = false;
    },
    async download() {
      const fileContent = await apiService.getConfigurationFile(this.configurationFile.id);

      this.$util.downloadAsFile(fileContent, this.configurationFile.filename);
    },
    editConfigurationFile() {
      this.$refs.CreateEditConfigurationFileRef.editConfigurationFile(this.configurationFile);
    },
    bubbleEmit() {
      this.getConfigurationFile().then(() => {
        this.$emit('configurationfile-updated');
      });
    },
    async deleteConfigurationFile() {
      if (confirm('Are you sure you want to delete this configuration file? This cannot be undone.')) {
        await apiService.deleteConfigurationFile(this.configurationFile.id);
        this.$router.back();
      }
    },
  },
  created() {
    this.getConfigurationFile();
  },
};
</script>

<style></style>
